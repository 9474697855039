import * as React from "react";
import { graphql } from 'gatsby';
import Blog, {
  BlogSubHeader,
  BlogList,
  BlogListItem,
  BlogSubMiniHeader,
  BlogUnorderList,
  BlogUnorderListItem
} from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/web-development-processes';
import { LeadingTrendsInWebApplicationDevelopment, WebDevelopmentProcesses, TypicalFrontEndDay, FrequentlyAskedQuestionsAndExamplesOfSaas } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const title = "Web development processes";

const articleSchemaData = {
  headline: title,
  description: 'SEO description web development processes',
  image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fweb-development-processes.jpg?alt=media&token=7ad86f61-f35c-4544-9fa3-61a749ba37c8',
  datePublished: '2022-02-08'
};

const WebDevelopmentProcessesBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={WebDevelopmentProcesses.url}
      banner="/web-development/banner.jpg"
      relatedBlogs={[FrequentlyAskedQuestionsAndExamplesOfSaas, LeadingTrendsInWebApplicationDevelopment, TypicalFrontEndDay]}
      title={title}
      seo={{
        title: 'SEO title web development processes',
        description: 'SEO description web development processes',
        image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fweb-development-processes.jpg?alt=media&token=7ad86f61-f35c-4544-9fa3-61a749ba37c8',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section2.h}
      </BlogSubHeader>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogList>
        {HE.section2.ol.map(({ subH, p }) => (
          <BlogListItem>
            <BlogSubMiniHeader>{subH}</BlogSubMiniHeader>
            <Paragraph>{p}</Paragraph>
          </BlogListItem>
        ))}
      </BlogList>

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>

      <BlogSubMiniHeader>
        {HE.section4.subH}
      </BlogSubMiniHeader>
      {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubMiniHeader>
        {HE.section5.subH}
      </BlogSubMiniHeader>
      {HE.section5.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubMiniHeader>
        {HE.section6.subH}
      </BlogSubMiniHeader>
      {HE.section6.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubMiniHeader>
        {HE.section7.subH}
      </BlogSubMiniHeader>
      {HE.section7.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section8.h}
      </BlogSubHeader>
      {HE.section8.p.map(p => <Paragraph>{p}</Paragraph>)}

      <Paragraph>
        <BlogUnorderList>
          {HE.section8.ul.map(p => (
            <BlogUnorderListItem>
              {p}
            </BlogUnorderListItem>
          ))}
        </BlogUnorderList>
      </Paragraph>

      {HE.section9.p.map(p => <Paragraph>{p}</Paragraph>)}

    </Blog>
  )
}

export default WebDevelopmentProcessesBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;