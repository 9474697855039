import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "תהליך פיתוח מערכות ואפליקציות WEB דורש ידע וניסיון רב, וצוות הפיתוח שלנו בחברת אפספורס מתמחה בפיתוח מוצרים חדשים מאפס ובשדרוג ורענון מערכות WEB ומוצרים קיימים. אנו משתמשים בטכנולוגיות המתקדמות והעדכניות ביותר על מנת להבטיח לכם פיתוח Web עם התוצאות המושלמות והטובות ביותר.",
      <>
        {`חשוב להדגיש כי צוות הפיתוח שלנו מספקת מעטפת שירותים רחבה ומקיפה, אנו מציעים תחת קורת גג אחת פתרון מדויק ומותאם אישית לפיתוח אפליקציות ומערכות Web, המומחיות שלנו היא `}
        <Link to={URL.FRONT_END_SERVICE} active>בפיתוח פרונט אנד</Link>
        {`  ובק אנד, ב`}
        <Link to={URL.FULL_STACK_SERVICE} active>פיתוח פול סטאק מקצה לקצה</Link>
        {`, `}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>באפיון ובעיצוב חוויית משתמש</Link>
        {` ו`}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>בייעוץ ובפיתוח SaaS</Link>.
      </>
    ],
  },
  section2: {
    h: "תהליך פיתוח Web",
    p: [
      "תהליך פיתוח אפליקציות WEB  מתאר את כל השלבים הנדרשים לפיתוח המערכת בהתאמה אישית לצרכי הלקוח ולדרישותיו הספציפיות, תוך התייחסות נכונה למאפייני קהל היעד והמשתמשים. למעשה, זהו תהליך מוסדר הכולל מסמך אפיון ראשוני של המערכת, הגדרת מטרות, פירוט שלבי התכנון, בדיקות והטמעה של האפליקציה או המערכת החדשה בתוך מערכות קיימות ובהתאמה אישית.",
      "שלב התכנון הוא קריטי להצלחת פיתוח אפליקציות ומערכות Web, שלב זה נועד למנוע טעויות ולהבטיח פתרונות גם במצבי קיצון. חשוב לזכור כי בפיתוח Web לא ממציאים את האינטרנט מחדש אלא מפתחים מערכות בעלות ממשקי Web הכוללות טכנולוגיות ויישומיים קיימים. לכן, סביבת הפיתוח המתאימה ביותר לפיתוח מערכות ואפליקציות ווב היא פול סטאק, כלומר פיתוח מקצה לקצה, ובמילים מקצועיות פיתוח צד לקוח ופיתוח צד שרת.",
      "בשלב הראשון יש להגדיר נכון את סוג המערכת ומטרותיה. יש סוגים שונים של מערכות Web, ונהוג לחלק את המערוכת לשלושה סוגים עיקריים:",
    ],
    ol: [
      {
        subH: "1. אתרי אינטרנט",
        p: "יש ביקוש רב לפיתוח אתרי אינטרנט המבוססים על טכנולוגיות חדשניות וכוללים יישומי Web שונים להפצת מידע, לשיתוף פעולה בין משתמשים רבים בתוך הארגון ו/או מחוץ לארגון ועוד."
      },
      {
        subH: "2. מערכות מידע עם ממשקי Web",
        p: "פיתוח מערכות מידע חדשות וגמישות בעלות ממשקי Web בהתאמה לדרישות הלקוח. הפיתוח כולל אפיון ועיצוב ממשקי משתמש למערכת המידע החדשה וחיבור המערכת."
      },
      {
        subH: "3. רכיבי Web למערכת קיימת",
        p: "לארגונים רבים יש מערכות מידע קיימות  ואיכותיות, במקום לפתח מערכת חדשה מקצה לקצה ניתן בעזרת פיתוח Web להוסיף תת מערכת אינטרנטית למערכת הקיימת של הלקוח ולחסוך עלויות רבות ולקצר את זמן הפיתוח הנדרש."
      },
    ]
  },
  section3: {
    h: "שלבים מרכזיים בתהליך פיתוח מערכות ואפליקציות Web"
  },
  section4: {
    subH: "שלב האפיון",
    p: [
      "שלב ראשון וקריטי בכל פיתוח Web, בשלב זה מבצעים ניתוח ראשוני ומעמיק של המערכת, מגדירים את מהות האפליקציה או האתר, בודקים אילו פיצ'רים חייבים להיות במערכת, בוחנים פתרונות ומערכות של מתחרים כדי ליצור בידול ולספק חוויית משתמש טובה יותר, מגבשים רעיונות לקונספט העיצוב הרצוי ומאפיינים יכולות התממשקות למערכות צד שלישי."
    ]
  },
  section5: {
    subH: "שלב העיצוב",
    p: [
      <>
        {`במסמך אפיון עבור פיתוח Web מתייחסים לקונספט `}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>העיצוב וחווית המשתמש</Link>.
        {` בשלב העיצוב של המערכת צוות מעצבים גרפיים בשילוב אנשי הפיתוח דואג לפן העיצובי ולחוויית השימוש במוצר הדיגיטלי, כאשר המטרה היא חוויית משתמש נוחה, פשוטה ומהירה, לכן המומחים שלנו בוחרים עיצוב מינימליסטי ומותאם אישית למאפייני הגולשים ולמטרת המערכת וליעדים העסקיים של הלקוח.`}
      </>
    ]
  },
  section6: {
    subH: "שלב הפיתוח",
    p: [
      "שלב מרכזי בכל תהליך אפליקציות ומערכות ווב. בשלב זה מתכננים ומפתחים את מערכת ניהול התוכן של המערכת או האתר, וצוות המפתחים אחראי על הפיכת התכנון למערכת או לאפליקציה שימושית, נוחה ויעילה. צוות המפתחים בוחר את שפות התכנות וסביבות הפיתוח המתאימות ביותר, וכאשר מפתחים אפליקציות Web יש להחליט מראש האם מתכננים אפליקציה לאנדרואיד או לאייפון, ויש להתאים את המערכת למסכי המובייל ולמכשירים הסלולריים."
    ]
  },
  section7: {
    subH: "שלב הבדיקה וסיום הפיתוח",
    p: [
      "פיתוח Web נעשה בשכבות עד השלמת פיתוח המוצר השלם, בדיוק כפי שבונים בית. כל אפליקציה מתחילה בתכנון ובאפיון מפורט ומדויק, ויש מבנה מקובל לפיתוח אפליקציות Web המבוסס על שלוש שכבות – הצגה, לוגיקת יישום ואחסון נתונים. בסיום שלב הפיתוח מבצעים בדיקות QA ידניות ואוטומטיות, מציגים את האפליקציה ללקוח ולאחר אישור מסיימים את הפרויקט."
    ]
  },
  section8: {
    h: "דגשים חשובים בתהליך פיתוח אפליקציות Web",
    p: [
      "ראוי לציין כי פיתוח אפליקציות לווב מציע יתרונות משמעותיים עבור הלקוח מאחר ותהליך הפיתוח כרוך בעלויות יחסית נמוכות וניתן לפתח את האפליקציה בשפת קוד המתאימה לכל המכשירים. כמו כן, אפליקציות Web עושות שימוש בדפדפנים כתוכנת לקוח ובצד השרת מבצעים את החישובים ועיבוד הנתונים על מנת להציג תוכן רלוונטי במסך של צד הלקוח. כדאי לדעת כי גם העדכון והתחזוקה של אפליקציית Web נוחים ומהירים יותר בהשוואה למערכות אחרות, אין צורך בהתקנות מורכבות וניתן לפתח אפליקציות שעושות שימוש בדפדפן לצרכי מסחר מקוון, דואר, הפצת מידע ועוד.",
      "בתהליך הפיתוח של אפליקציות ומערכות Web יש להתייחס נכון לדגשים הבאים:"
    ],
    ul: [
      <>
        <strong>מודולריות</strong>
        {` – יש להקפיד על תכנון מודולרי המאפשר בעתיד להוסיף פיצ'רים חדשים למערכת בדרך קלה ובעלות נמוכה. בנייה מודולרית של האפליקציה מאפשר לבצע תחזוקה שוטפת ולהוסיף פיצ'רים חדשים ברקע, ללא פגיעה בתפקוד השוטף של המערכת ובלי לשנות שכבות קיימות באפליקציה.`}
      </>,
      <>
        <strong>יכולות שחזור</strong>
        {` – המערכת חייבת לכלול פתרון מהיר ונוח לשחזור המידע לאחר קריסה, לכן יש לכלול אפשרויות גיבוי ושחזור.`}
      </>,
      <>
        <strong>הרשאות</strong>
        {` – בשלב האפיון של האפליקציה יש להגדיר הרשאות לפי תפקידים ומאפייני השימוש במערכת.`}
      </>,
      <>
        <strong>חוויית משתמש</strong>
        {` – פיתוח Web כולל בין היתר אפיון ועיצוב UX/UI, יש לעצב ממשקים ומסכים נוחים וברורים המבטיחים למשתמשים שימוש קל, מהיר ויעיל באפליקציה. חוויית משתמש נוחה ופשוטה מבטיחה משתמשים קבועים במערכת, בידול מהמתחרים, מיתוג מנצח והצלחה של האפליקציה.`}
      </>,
    ]
  },
  section9: {
    p: [
      <>
        {`צוות חברת הפיתוח `}
        ו<Link to={URL.HOME} active>בית התוכנה אפספורס</Link>.
        {` מתמחה בפיתוח אפליקציות Web ומתחייבת למערכות ולאפליקציות מתקדמות, נוחות ואיכותיות, המבוססות על כלים טכנולוגיים מתקדמים וחדשניים, עם ממשקים נוחים, ואינטגרציה לפלטפורמות סלולריות וליישומים קיימים.`}
      </>
    ]
  }
};